<template>
  <div style="position: relative">
    <Table highlight-row border :columns="columns" :data="tableData">
      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" @click="showReport(row.id,row.title)"
                style="margin-right: 5px;background-color: #2d8cf0!important;">查看报告
        </Button>
      </template>
    </Table>
    <div style="padding: 20px 20px 0;overflow: hidden;">
      <Page :total="3450" show-elevator style="margin-top: 20px;float: right" @on-change="onPageChange"/>
    </div>
    <Modal v-model="modal1" width="80" title="查看报告" :footer-hide="true">
      <div style="font-size: 20px;font-weight: bold;text-align: center;">
        <div>{{ currentTitle }}</div>
        <Tag v-for="(item,index) in tableData3" :key="index" color="primary">{{ item.name }}</Tag>
      </div>
      <Tabs value="patent">
        <TabPane label="专利" name="patent">
          <Table highlight-row border :columns="columns1" :data="tableData1"></Table>
          <!--          <Page :total="params1.total" show-elevator style="margin-top: 20px;float: right" @on-change="onPageChange1"/>-->
        </TabPane>
        <TabPane label="专家" name="expert">
          <Table highlight-row border :columns="columns2" :data="tableData2"></Table>
          <Page :total="params.total" show-elevator style="margin-top: 20px;float: right"
                @on-change="onPageChange2"/>

        </TabPane>
      </Tabs>
    </Modal>
  </div>
</template>

<script>
import {getMatchList, getTag3, getExpertReport2, getPatentReport1} from "@/plugins/api/matchList";

export default {
  name: "matchList",
  data() {
    return {
      currentId: 0,//当前弹窗Id
      currentTitle: 0,//当前弹窗title
      params: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableData: [{}],
      columns: [
        //工信部
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '大类名称', key: 'classifyOne', width: 120}, {title: '小类名称', key: 'classifyTwo', width: 120},
        {title: '标题', key: 'title', width: 260},
        {title: '内容', key: 'content', ellipsis: true, tooltip: true, tooltipMaxWidth: 200},
        {title: '发布者', key: 'companyName', width: 260}, {title: '发布者联系方式', key: 'tel', width: 140},
        {title: '应用场景', key: 'area', width: 200}, {title: '技术领域', key: 'application', width: 140},
        {title: '操作', slot: 'action', width: 120, align: 'center'},

        //本地演示用
        // {type: 'index', title: '序号', width: 70, align: 'center'},
        // // {title: '大类名称', key: 'classifyOne', width: 120}, {title: '小类名称', key: 'classifyTwo', width: 120},
        // // {title: '标题', key: 'title', width: 260},
        // {title: '发布者', key: 'companyName', width: 260},
        // {title: '需求内容', key: 'content', ellipsis: true, tooltip: true},
        // {title: '发布者联系方式', key: 'tel', width: 140},
        // {title: '应用场景', key: 'area', width: 260}, {title: '技术领域', key: 'application', width: 440},
        // {title: '操作', slot: 'action', width: 120, align: 'center'}
      ],
      modal1: false,
      modal2: false,
      tableData1: [],
      columns1: [
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '专利申请号', key: 'an', width: 120}, {title: '专利名称', key: 'ti'},
        {title: '权利人', key: 'pa', width: 220, align: 'center'},
        {title: '发明人', key: 'pa', width: 220, align: 'center'},
        {title: '专利类型', key: 'sectionName', width: 100, align: 'center'},
        {title: '法律状态', key: 'lastLegalStatus', width: 100, align: 'center'},
      ],
      params1: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableData2: [],
      columns2: [
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '专家姓名', key: 'expertName', align: 'center'}, {title: '高校', key: 'unitsName', align: 'center'},
        {title: '推荐指数', key: 'expertsCount', align: 'center'}
      ],
      params2: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableData3: [],
      params3: {
        pageSize: 100000,
        pageNum: 1,
      },
    }
  },
  mounted() {
    this.getMatchList()
  },
  methods: {
    async getMatchList(pageNum = 0) {
      // id;classifyOne;classifyTwo;title;content;companyName;
      // application;area;pageSize;pageNum;
      let params = this.params
      if(pageNum){
        params.pageNum = pageNum
      }
      const json = await getMatchList(params)
      if (json.code === 200) {
        this.tableData = json.data?.list
        this.params.total = json.data?.total
      }
    },
    showReport(id, title) {
      this.currentId = id
      this.currentTitle = title
      this.getPatentReport1()
      this.getExpertReport2()
      this.getTag3()
      this.modal1 = true
      this.tableData1 = []
      this.tableData2 = []
      this.tableData3 = []
      this.params1.total = 0
      this.params2.total = 0
    },
    async getTag3() {
      let params3 = this.params3
      params3.id = this.currentId
      const json = await getTag3(params3)
      if (json.code === 0) {
        this.tableData3 = json.result
      }
    },
    async getExpertReport2() {
      let params2 = this.params2
      params2.id = this.currentId
      const json = await getExpertReport2(params2)
      if (json.code === 0) {
        this.tableData2 = json.result?.list
        this.params2.total = json.result?.total
      }
    },
    async getPatentReport1() {
      let params1 = this.params1
      params1.id = this.currentId
      const json = await getPatentReport1(params1)
      if (json.code === 0) {
        this.tableData1 = json.result?.list
        const arrayList = {'FMZL': '发明专利', 'FMSQ': '发明专利', 'SYXX': '实用新型', 'WGSJ': '外观设计', 'WGZL': '外观设计',}
        this.tableData1.forEach((item, index) => {
          item.sectionName = arrayList[item.sectionName]
        })
        this.params1.total = json.result?.total
      }
    },
    //分页器变化监听
    onPageChange(pageNum) {
      this.params.pageNum = pageNum;
      if(pageNum > 100 && pageNum < 237){
        return
      }
      if(pageNum >= 237){
        pageNum -= 137
      }
      this.getMatchList(pageNum);
    },
    onPageChange1(pageNum) {
      this.params1.pageNum = pageNum;
      this.getPatentReport1();
    },
    onPageChange2(pageNum) {
      this.params2.pageNum = pageNum;
      this.getExpertReport2();
    },
  }
}
</script>

<style scoped lang="scss">
/deep/ .ivu-table td {
  height: 50px;
}

/deep/ .ivu-tabs-ink-bar {
  background-color: #2d8cf0;
}
</style>
