import {get, post, put} from "../http/http";
import {qxtUrl} from "../http/baseUrl";

export const getMatchList = (params) => {
    return post(`${qxtUrl}/dp/macth/list`, params);
}
export const getTag3 = (params) => {
    return get(`${qxtUrl}/enterprise/request/tag2`, params);
}
export const getPatentReport1 = (params) => {
    return get(`${qxtUrl}/enterprise/patent/report2`, params);
}
export const getExpertReport2 = (params) => {
    return get(`${qxtUrl}/enterprise/expert/report2`, params);
}
